/*Importing Jost, Oswald fonts*/
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700&family=Oswald:wght@500&display=swap');

/*Colours: https://lospec.com/palette-list/ayy4*/

* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

body {
  background-color: #f1f2da;
}

.wrapper {
  position: absolute;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.inner_wrapper {
  height: 100%;
  width: 100%;
  max-width: 1400px;
}

/*----------------- ANIMATION SECTION -----------------*/

.animation {
  opacity: 1;
}

.animateUP {
  animation: goingUp 1.5s forwards;
}

.animateLEFT {
  animation: goingLeft 1.5s forwards;
}

.animateRIGHT {
  animation: goingRight 1.5s forwards;
}

@keyframes goingUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes goingLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes goingRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/*----------------- HEADER SECTION -----------------*/
.header {
  width: 100%;
  height: 10vh;
  min-height: 50px;
  margin-bottom: 50px;
  display: block;
  background-color: #00303b;
  position: fixed;
  top: 0;
  color: white;
  z-index: 2;
}

.inner_header {
  width: 85%;
  height: 100%;
  display: block;
  margin: 0 auto;
}

.initials_container {
  height: 100%;
  display: table;
  float: left;
}

.initials_container a {
  display: table-cell;
  vertical-align: middle;
}

.initials_container h1 {
  color: white;
  font-family: 'Jost', sans-serif;
  font-size: 35px;
  user-select: none;
  transition: 0.25s ease;
}

.initials_container :hover {
  opacity: 0.7;
}

.navigation {
  float: right;
  height: 100%;
  font-family: 'Oswald', sans-serif;
  font-size: 3vh;
  user-select: none;
}

.navigation a {
  height: 100%;
  font-size: 20px;
  display: table;
  float: left;
  padding: 0px 20px;
  color: white;
}

.navigation a :hover {
  opacity: 0.7;
}

.navigation a li {
  display: table-cell;
  vertical-align: middle;
  transition: 0.25s ease;
}

/*----------------- MODAL SECTION -----------------*/

.modal_wrapper {
  display: none;
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: 0.2s ease;
  background-color: rgba(0, 0, 0, 0.4);
}

.inner_modal {
  overflow: hidden;
  display: block;
  width: 60%;
  height: 70%;
  background-color: white;
  margin: 12vh auto;
  padding: 40px;
  border-radius: 24px;
  font-family: 'Jost', sans-serif;
  color: #00303b;
}

.inner_modal h2 {
  width: 100%;
  text-align: center;
  font-weight: 600;
}

.modal_text_container {
  overflow-y: scroll;
  margin-top: 1%;
  height: 90%;
}

.inner_modal h3 {
  width: 100%;
  font-weight: 400;
}

/*----------------- INTRODUCTION SECTION -----------------*/

.intro {
  margin-top: 8vmax;
  height: 91vh;
  min-height: 500px;
  max-height: 1000px;
  display: block;
  font-family: 'Jost', sans-serif;
}

.inner_intro {
  width: 80%;
  height: 88%;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: middle;
}

.intro_left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
}

.intro_text_container {
  height: 25%;
  width: 100%;
  display: table;
}

.intro_text_container h2 {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-size: 4vw;
  font-weight: 500;
  color: #00303b;
}

.link_container {
  margin-top: 2%;
  display: flex;
  user-select: none;
}

.link_container a {
  font-size: 2.5vw;
  font-weight: 400;
  margin: auto;
  color: white;
  background-color: #00303b;
  padding: 5px 5px;
  width: 30%;
  min-width: fit-content;
  text-align: center;
  border-radius: 30px;
  transition: 0.25s ease;
}

.link_container button {
  border: 0px;
  font-family: 'Jost', sans-serif;
  font-size: 2.5vw;
  font-weight: 400;
  margin: auto;
  color: white;
  background-color: #00303b;
  padding: 5px 5px;
  width: 50%;
  border-radius: 30px;
  transition: 0.25s ease;
}

.link_container :hover {
  background-color: #ff7777;
}

.intro_img_container {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro_img_container img {
  max-width: 60%;
  height: auto;
  border-radius: 50%;
  border: 1.2vw solid #00303b;
}

/*----------------- SCROLL SECTION -----------------*/

.scroll {
  color: #00303b;
  width: 100%;
  text-align: center;
  transition: 0.5s ease;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.scroll h3 {
  font-family: 'Jost', sans-serif;
  font-size: 28px;
  font-weight: 400;
}

.scroll span {
  margin-top: 2vw;
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -1vw;
  border-left: 2px solid #00303b;
  border-bottom: 2px solid #00303b;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: scrollDown 1.5s infinite;
  animation: scrollDown 1.5s infinite;
  box-sizing: border-box;
}

@-webkit-keyframes scrollDown {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}

@keyframes scrollDown {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}

/*----------------- FEATURED PROJECT AND TEMPLATE SECTION -----------------*/

.f_proj {
  width: 100%;
  height: 90vh;
  min-height: 700px;
  max-height: 1000px;
  margin-bottom: 4vh;
  font-family: 'Jost', sans-serif;
  color: #00303b;
}

.f_proj h2 {
  margin: auto;
  background-color: white;
  text-align: center;
  width: 30%;
  font-size: 3vw;
  font-weight: 400;
  color: #00303b;
  border-radius: 20px;
}

.project_wrapper {
  width: 100%;
  height: 90vh;
  min-height: 700px;
  max-height: 1000px;
}

.project {
  width: 100%;
  height: 90%;
  max-height: 800px;
  font-family: 'Jost', sans-serif;
  color: #00303b;
}

.project_wrapper .project {
  height: 100%;
}

.project :hover {
  transform: scale(1.02);
  box-shadow: 25px 25px #00303b;
}

.inner_proj {
  position: relative;
  width: 90%;
  height: 90%;
  margin-left: 5%;
  border-radius: 50px;
  background-color: white;
  display: block;
  transition: 0.2s ease;
}

.inner_proj :hover {
  transform: scale(1);
  box-shadow: 0px 0px #00303b;
}

.inner_proj h2 {
  background-color: transparent;
  width: 100%;
  font-size: 3vw;
  font-weight: 400;
  padding-top: 2%;
  text-align: center;
}

.date {
  position: absolute;
  font-size: 14px;
  top: 4%;
  right: 5%;
}

.proj_flex {
  display: flex;
  height: 80%;
}

.proj_left {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.proj_left img {
  border-radius: 20px;
  width: 84%;
  border: 10px solid #f1f2da;
}

.proj_right {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.proj_text_container {
  width: 90%;
  margin-bottom: 4%;
  overflow-y: scroll;
}

.proj_right h3 {
  text-align: justify;
  font-family: 'Jost', sans-serif;
  font-size: 20px;
  font-weight: 400;
}

.proj_flex .link_container {
  width: 90%;
}

.proj_flex .link_container a {
  width: 40%;
  min-width: fit-content;
}

/*----------------- RECENT BLOG AND TEMPLATE SECTION -----------------*/

.r_blog {
  width: 100%;
  height: 80vh;
  min-height: 700px;
  max-height: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Jost', sans-serif;
}

.blog_wrapper {
  width: 100%;
  height: 70vh;
  min-height: 600px;
  max-height: 1000px;
}

.r_blog h2 {
  background-color: white;
  text-align: center;
  width: 30%;
  font-size: 3vw;
  font-weight: 400;
  color: #00303b;
  border-radius: 20px;
}

.blog_row {
  width: 100%;
  height: 70%;
  font-family: 'Jost', sans-serif;
}

.blog_wrapper .blog_row {
  height: 100%;
}

.blog_row :hover {
  transform: scale(1.02);
  box-shadow: 15px 15px #00303b;
}

.blog_post {
  position: relative;
  color: #00303b;
  float: left;
  height: 80%;
  max-height: 500px;
  width: 40%;
  background-color: white;
  border-radius: 40px;
  margin-top: 4vh;
  margin-left: 5%;
  margin-right: 5%;
  overflow: hidden;
  transition: 0.2s ease;
}

.blog_post :hover {
  transform: scale(1);
  box-shadow: 0px 0px #00303b;
}

.blog_post h3 {
  position: relative;
  padding-top: 4%;
  width: 100%;
  font-size: 160%;
  font-weight: 400;
  color: #00303b;
  text-align: center;
}

.blog_post h4 {
  width: 90%;
  height: 65%;
  padding: 5%;
  padding-top: 2%;
  font-weight: 400;
  font-size: 120%;
  text-align: justify;
  color: #00303b;
  white-space: pre-wrap;
  overflow-y: scroll;
}

.r_blog .link_container {
  width: 20%;
  margin-bottom: 50px;
}

.r_blog .link_container a {
  width: 70%;
}

.whiteBox {
  width: 100%;
  height: 52px;
  background-color: white;
  position: absolute;
  bottom: 0;
  right: 0;
}

.enlarger {
  position: absolute;
  bottom: 4%;
  right: 4%;
  background-color: white;
  user-select: none;
}

.enlarger h2 {
  font-size: 24px;
  width: 100%;
  background-color: transparent;
  user-select: none;
  transition: 0.25s ease
}

.enlarger :hover {
  opacity: 0.8;
}

/*----------------- FOOTER SECTION -----------------*/

.footer {
  height: 10vh;
  width: 100%;
  font-family: 'Jost', sans-serif;
  color: white;
}

.footer h4 {
  width: 100%;
  text-align: center;
  font-weight: 400;
  opacity: 0.3;
}

.inner_footer {
  height: auto;
  width: 100%;
  background-color: #00303b;
  position: absolute;
  bottom: 0;
  left: 0;
}

.icons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icons img {
  height: 40px;
  margin: 15px 10px 0px 10px;
  transition: 0.2s ease;
}

.icons a :hover {
  opacity: 0.8;
}


/*----------------- SUBPAGES SECTION -----------------*/

.page_intro {
  margin-top: 10vh;
  height: 20vh;
  width: 100%;
  display: flex;
  align-items: center;
}

.page_intro h1 {
  font-size: 3.2vw;
  width: 20%;
  min-width: fit-content;
  text-align: center;
  margin: auto;
  padding: 10px 10px;
  font-family: 'Jost', sans-serif;
  font-weight: 500;
  color: white;
  background-color: #00303b;
  border-radius: 40px;
}

/* Locks scale for wider monitors */
@media screen and (min-width:1400px) {
  .intro_text_container h2 {
    font-size: 360%;
  }

  .link_container a {
    font-size: 200%;
  }

  .link_container button {
    font-size: 200%;
  }

  .intro_img_container img {
    border: 16px solid #00303b;
  }

  .f_proj h2 {
    font-size: 260%;
  }

  .project h2 {
    font-size: 260%;
  }

  .r_blog h2 {
    font-size: 260%;
  }
}

/* fixes clip with blog title */
@media screen and (max-width:1100px) {
  .date {
    display: none;
  }
}

/* Tablet view */
@media screen and (max-width:800px) {
  .animation {
    opacity: 1;
  }

  /* INTRODUCTION SECTION */
  .inner_intro {
    flex-direction: column-reverse;
  }

  .intro_left {
    width: 100%;
  }

  .intro_text_container h2 {
    font-size: 5vw;
  }

  .intro_img_container {
    width: 100%;
    height: fit-content;
  }

  .intro_img_container img {
    max-width: 40%;
  }

  .link_container a {
    font-size: 4vw;
  }

  /* SCROLL SECTION */
  .scroll span {
    margin-top: 3vh;
    margin-left: -3vw;
  }

  /* FEATURED PROJECT SECTION */
  .f_proj {
    height: 120vh;
    margin-bottom: 0vh;
  }

  .f_proj h2 {
    width: 50%;
    font-size: 5vw;
  }

  .f_proj .project {
    height: 96%;
  }

  .project :hover {
    transform: scale(1);
    box-shadow: 10px 10px #00303b;
  }

  .inner_proj :hover {
    transform: scale(1);
    box-shadow: 0px 0px #00303b;
  }

  .inner_proj h2 {
    width: 100%;
  }

  .proj_flex {
    flex-direction: column;
  }

  .proj_left {
    display: none;
  }

  .proj_text_container {
    padding: 5%;
  }

  .proj_right {
    width: 100%;
  }

  .proj_flex .link_container {
    width: 100%;
  }

  /* RECENT BLOG SECTION */
  .r_blog {
    height: 160vh;
  }

  .r_blog h2 {
    width: 50%;
    font-size: 4vw;
  }

  .blog_row {
    flex-direction: column;
    height: 90%;
  }

  .blog_row :hover {
    transform: scale(1);
    box-shadow: 10px 10px #00303b;
  }

  .blog_post :hover {
    transform: scale(1);
    box-shadow: 0px 0px #00303b;
  }

  .blog_post {
    height: 45%;
    width: 90%;
  }

}


/* Mobile view */
@media screen and (max-width:500px) {

  /* HEADER SECTION */
  .initials_container h1 {
    font-size: 32px;
  }

  .navigation a {
    font-size: 20px;
    padding: 0px 10px;
  }

  /* INTRODUCTION SECTION */
  .intro_left {
    width: 100%;
  }

  .intro_text_container h2 {
    font-size: 30px;
  }

  .intro_img_container img {
    border: 12px solid #00303b;
  }

  .link_container {
    flex-direction: column;
  }

  .link_container a {
    font-size: 20px;
    width: 60%;
    margin-top: 10px;
  }

  /* FEATURED PROJECT SECTION */

  .f_proj h2 {
    width: 65%;
    font-size: 30px;
  }

  .inner_proj h2 {
    margin: auto;
    width: 80%;
    font-size: 25px;
  }

  .proj_text_container {
    padding: 5% 10% 5% 10%;
    width: 80%;
  }

  .proj_text_container h3 {
    font-size: 18px;
  }

  .proj_flex {
    flex-direction: column;
  }

  .proj_right {
    width: 100%;
  }

  /* RECENT BLOG SECTION */
  .r_blog h2 {
    font-size: 30px;
    width: 80%;
  }

  /* SUBPAGE SECTION */
  .page_intro h1 {
    width: 40%;
    font-size: 30px;
  }
}